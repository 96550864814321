import React from 'react'
import { Link } from 'gatsby'
import AreaItem from './AreaItem'

const Areas = ({ menu }) => {
    return (
        <section className='areas-area py-4'>
            <div className='container'>
                <div className='section-title'>
                    <h2>Enlaces de interés</h2>
                </div>
                <div className='row'>
                    {menu !== null && menu.map((item) => {
                        return (
                            <div
                                className='col-lg-3 col-md-6'
                                key={item.id}
                            >
                                {item.newwindow ?
                                    <a href={item.href} target='_blank' rel='noreferrer'>
                                        <AreaItem
                                            item={item}
                                        />
                                    </a>
                                    :
                                    <Link
                                        to={item.href}
                                    >
                                        <AreaItem
                                            item={item}
                                        />
                                    </Link>
                                }
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default Areas