import api from './credentials'
export async function getPosts(category, page) {
    try {
        let params = {}
        if (page) params.page = page
        if (category) params.category = category
        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
        const data = await api.get(`${process.env.API_URL}/posts?${query}`)
            .then(res => {
                return res.data
            })
            .catch(err => {
                console.log(err);
                return []
            })
        return data

    } catch (error) {
        return []
    }


}

export async function getLessPosts() {
    const data = await api.get(`${process.env.API_URL}/posts/less`)
        .then(res => res.data)
        .catch(err => {
            console.log(err);
            return []
        })
    return data
}

export async function getPost(slug) {
    const data = await api.get(`${process.env.API_URL}/post?slug=${slug}`)
        .then(res => {
            return res.data
        })
        .catch(err => {
            console.log(err);
            return []
        })
    return data
}
