import React from 'react'
import { Link } from 'gatsby'
import CurrentItem from './CurrentItem'

const Current = ({ menu }) => {
    return (
        <section className='areas-area current bg-color py-4 bg-fffbf5'>
            <div className='container'>
                <div className='section-title'>
                    <h2>Actualidad</h2>
                </div>
                <div className='row'>
                    {menu !== null && menu.map((item) => {
                        return (
                            <div
                                className='col-lg-4 col-md-6'
                                key={item.id}
                            >
                                {item.newwindow ?
                                    <a href={item.href} target='_blank' rel='noreferrer'>
                                        <CurrentItem
                                            item={item}
                                        />
                                    </a>
                                    :
                                    <Link
                                        to={item.href}
                                    >
                                        <CurrentItem
                                            item={item}
                                        />
                                    </Link>
                                }
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default Current