import React from 'react'
import ReactWOW from 'react-wow'
import { Link } from 'gatsby'

const Banner = ({ settings }) => {
    return (
        <div className='index-banner'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-lg-6 col-md-12'>
                        <div className='main-banner-content'>
                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <h2>{settings.find(x => x.key === 'welcome.title') ? settings.find(x => x.key === 'welcome.title').value : 'welcome.title'}</h2>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <p>{settings.find(x => x.key === 'welcome.subtitle') ? settings.find(x => x.key === 'welcome.subtitle').value : 'welcome.subtitle'}</p>
                            </ReactWOW>

                            {typeof window !== 'undefined' && !localStorage.getItem('user') ? 
                                <ReactWOW delay='.1s' animation='fadeInRight'>
                                    <div className='btn-box'>
                                        <Link to='/signup' className='default-btn'>
                                            <i className='flaticon-right'></i> 
                                            Regístrate <span></span>
                                        </Link>
                                    </div>
                                </ReactWOW>
                                :
                                ''    
                            }
                        </div>
                    </div>

                    <div className='col-lg-6 col-md-12'>
                        {/* <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className='main-banner-image'>
                            <img alt={settings.find(x => x.key === 'welcome.image') ? settings.find(x => x.key === 'welcome.image').value : 'Banner'} src={settings.find(x => x.key === 'welcome.image') && settings.find(x => x.key === 'welcome.image').media.length > 0 ? settings.find(x => x.key === 'welcome.image').media[0].original_url : ''} />
                            </div>
                        </ReactWOW> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner