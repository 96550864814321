import React from 'react'

const AreaItem = ({ item }) => {
    const { description, icon, name } = item

    return (
        <div className='single-areas-box'>
            <div className='image'>
                <img src={`${process.env.BACKEND_URL}/${icon}`} alt={name} />

                <div className='link-btn'>
                    <i className='bx bx-plus'></i>
                </div>
            </div>

            <div className='content'>
                <h3>{name}</h3>
                <span>{description}</span>
            </div>
        </div>
    )
}

export default AreaItem